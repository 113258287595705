/* Copyright 2021 Scout Exchange, LLC. All Rights Reserved. */

.rejection-reasons-chart {
  // prevent the Reject Reasons section from shrinking
  flex-shrink: 0;
  height: auto;
  margin-right: spacer(8);
  width: px-to-rem(215);

  td {
    padding-top: spacer(0.5);
    padding-bottom: spacer(0.5);
  }

  .donut-data {
    height: px-to-rem(100);
    width: px-to-rem(100);
  }
}

.rejection-reasons-header {
  line-height: 1.1;
  margin: spacer(4) spacer(2);
  text-align: center;
}
