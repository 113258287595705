// Copyright 2019 Scout Exchange, LLC. All Rights Reserved.

// ===========================
// Styles for the Require Authorization screen
// ===========================

.auth-wrap {
  border-top: $navbar-border-height solid $scout-action;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;
  width: 100%;

  h1 {
    margin: 0 px-to-rem(20) px-to-rem(15);
    text-align: center;
  }

  p {
    margin: 0 px-to-rem(20);
    text-align: center;
  }
}

.auth-brand {
  background-color: $scout-primary;
  border-radius: px-to-rem(8);
  margin: 0 px-to-rem(20) px-to-rem(30);
  padding: px-to-rem(20) px-to-rem(30) px-to-rem(25);
  width: 350px;

  .aquent-scout-logo {
    width: 100%;
  }
}
