/* Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved. */

// ===========================
// Placements widget
// ===========================

.placements-wrapper {
  display: flex;
  flex-direction: column;
  font-size: $small-font-size;
  height: 100%;
  padding: $widget-body-pad-top $widget-body-pad-horiz $widget-body-pad-bottom;
  width: 100%;
}

.placements-body {
  margin-bottom: auto;
}

.placements-section-title {
  border-bottom: 1px solid $primary-variant-lighter;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  margin-bottom: spacer(1);
  padding-bottom: px-to-rem(6);
  padding-top: px-to-rem(29);
}

.placements-footer {
  font-size: $small-font-size;
}

.placements-list {
  @extend .list-unstyled;

  margin-bottom: 9.5px;
  padding-top: spacer(2);
}

// styles for each Placement
.placement-item {
  align-items: flex-start;
  display: flex;
  margin-bottom: 0.5em;
}

.placement-details {
  flex: 1;
  min-width: 0;
}

.placement-candidate,
.placement-title {
  @include truncate-right;

  display: block;
}

.placement-title-reqid-wrap {
  align-items: flex-start;
  display: flex;
}

.placement-title {
  padding-right: 0.5em;
}

.placement-status {
  margin-left: spacer(4);
  text-align: right;
  white-space: nowrap;
  width: 28%;
}
