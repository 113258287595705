/* Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved. */

@mixin truncate-right {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin truncate-left {
  @include truncate-right;

  // Add ellipsis to the beginning of the text:

  /* 1. change text direction to "right-to-left",
        which applies ellipsis on the left */
  direction: rtl;

  // 2. push text to the left side of the container
  text-align: left;
}

@mixin truncate-req-id {
  display: flex;

  // use percent width so that it's relative to its container
  max-width: 40%;

  // use rem width since a percentage could be too narrow
  min-width: px-to-rem(50);

  > span {
    @include truncate-left;
  }
}

@mixin font-clamp($sm, $mid, $lg) {
  /* default declaration for browsers like IE11
     that don't support "clamp()" */
  font-size: $lg;

  /* preferred font-size calculation for browsers
     that support "clamp()" */
  font-size: clamp($sm, $mid, $lg);
}
