/* Copyright 2020 Scout Exchange, LLC. All Rights Reserved. */

// ===========================
// Candidate Pipeline widget
// ===========================

.pipeline-wrapper {
  padding: spacer(8);
  width: 100%;
}

.pipeline-header {
  margin: 0 0 spacer(8);
  text-align: center;
}

.pipeline-details {
  display: flex;
  align-items: center;
}
