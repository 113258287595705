// Copyright 2021-2023 Scout Exchange, LLC. All Rights Reserved.

.charts-wrapper {
  display: flex;
  height: 100%;
  padding: $widget-body-pad-top $widget-body-pad-horiz $widget-body-pad-bottom;
  width: 100%;
  justify-content: center;
  align-content: center;
}
