/* Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved. */

// ===========================
// Widget basics
// ===========================

.widget {
  // width is defined on .variables
  background-color: $white;
  border: 1px solid $primary-variant-lighter;
  display: flex;
  flex-direction: column;
}

.widget-header {
  align-items: center;
  border-bottom: 1px solid $primary-variant-lighter;
  display: flex;
  flex: 0;
  padding: $widget-head-pad-vert $widget-head-pad-horiz;
  min-height: px-to-rem(40);

  .widget-title {
    margin-right: auto;

    // give some space between widget text and elements to the right
    padding-right: px-to-rem(10);
  }

  .widget-options {
    background-color: transparent;
    border: none;
    color: $scout-action;
    cursor: pointer;
    margin-left: auto;

    &:hover {
      color: $scout-primary;
      text-decoration: none;
    }
  }

  .btn-dropdown {
    max-width: 125px;
  }
}

.widget-body {
  display: flex;
  overflow-y: auto;
  height: 100%;

  .loader {
    margin: 0 auto;
  }
}

.widget-message {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $widget-body-pad-top $widget-body-pad-horiz $widget-body-pad-bottom;
  text-align: center;
  width: 100%;
  color: $no-results;

  img {
    margin: 0 0 spacer(4);
  }

  p {
    margin-bottom: spacer(1);
  }
}

.widget-chart-zero-state {
  text-align: center;

  h3 {
    margin: spacer(4) 0;
  }

  svg {
    margin: spacer(4) 0;
  }
}
