// Copyright 2021-2023 Scout Exchange, LLC. All Rights Reserved.

.which-dash-wrap {
  display: flex;
  justify-content: center;

  @media (min-width: $md-break) {
    position: absolute;
    width: 100%;
  }
}

.dash-jod {
  position: relative;

  .which-dash-wrap {
    background-color: $dash-bg;

    @media (min-width: $md-break) {
      background-color: transparent;
    }
  }
}

.which-dash-toggle {
  display: flex;
  margin-top: spacer(2);
  width: $widget-width;

  .btn {
    flex-grow: 1;
    justify-content: center;

    input {
      display: none;
    }
  }

  @media (min-width: $md-break) {
    margin-top: px-to-rem(38);
  }
}
