/* Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved. */

// ===========================
// My Jobs widget
// ===========================

.jobs-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $widget-body-pad-top $widget-body-pad-horiz $widget-body-pad-bottom;
  width: 100%;
}

.jobs-body {
  margin-bottom: auto;
}

.jobs-footer {
  font-size: $small-font-size;

  .more-info {
    float: right;
  }
}

.jobs-message {
  margin: 2em 0;
  text-align: center;
}

// list of jobs

.jobs-job-list {
  @extend .list-unstyled;

  font-size: $small-font-size;
  margin-bottom: 9.5px;
}

.jobs-job-item {
  display: flex;
  margin-bottom: 0.5em;
}

.jobs-expiring-soon {
  margin-left: spacer(4);
  white-space: nowrap;
}

.job-status-paused {
  float: right;
  text-align: right;
}

.expiring-paused {
  color: $brand-primary;
  text-align: right;
}

.jobs-job-details {
  @include truncate-right;

  display: block;
  margin-right: auto;
  padding-right: 0.5em;
}
