/* Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved. */

.dash-header-wrap {
  margin-bottom: spacer(3);
}

.inline-button-dropdown {
  display: flex;
  align-items: center;

  label {
    color: $scout-primary;
    font-weight: $font-weight-semibold;
    margin-right: spacer(1.25);
    margin-bottom: 0;
    position: relative;
    z-index: $zindex-dropdown;
  }
}

.country {
  margin-right: spacer(3);
}

.dash-header-inline {
  align-items: flex-start;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  margin: spacer(7) 0 0;

  h1 {
    margin: 0 0 spacer(3);

    @media (min-width: $sm-break) {
      margin: 0;
    }
  }

  .btn-row {
    > .btn-group {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .currency-display {
    color: $scout-primary;
    font-size: $small-font-size;
    margin-right: spacer(6);
    margin-bottom: spacer(1.5);
  }

  @media (min-width: $sm-break) {
    align-items: center;
    flex-direction: row;
  }
}
