/* Copyright 2020 Scout Exchange, LLC. All Rights Reserved. */

// ===========================
// Performance widget
// ===========================

.performance-wrapper {
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  width: 100%;
}

.performance-overview {
  padding: spacer(8);
  width: px-to-rem(280);
}

.performance-item {
  padding: spacer(8) spacer(7);
  text-align: center;
}

.timeliness {
  padding-left: 0;
  padding-right: 0;
  margin-right: 20px;
}

.expiration {
  padding-left: 0;
  padding-right: 0;
  margin-left: -40px;
}

.performance-overview-header {
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  gap: 4px;

  h2 {
    font-size: 18px;
  }

  .rating-inline {
    padding-top: 2px;
  }
}
