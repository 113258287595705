/* Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved. */

// ===========================
// Candidates widget
// ===========================

.candidates-wrapper {
  display: flex;
  flex-direction: column;
  padding: $widget-body-pad-top $widget-body-pad-horiz $widget-body-pad-bottom;
  width: 100%;
}

.candidates-body {
  margin-bottom: auto;
}

.candidates-list {
  @extend .list-unstyled;

  font-size: $small-font-size;
  margin-bottom: 9.5px;
}

// styles for each Candidate
.candidate-item {
  align-items: flex-start;
  display: flex;
  margin-bottom: 0.5em;
}

.candidate-status {
  text-align: right;
  white-space: nowrap;
}

.candidate-name-info,
.candidate-job-info {
  display: flex;
  max-width: 100%;
  padding-right: 1em;

  .truncate-title {
    @include truncate-right;
  }
}

.candidate-details {
  flex: 1;
  min-width: 0;
}

.candidate-job-reqid {
  @include truncate-req-id;
}

.candidate-job-info {
  > span {
    padding-right: px-to-rem(4);
  }
}

.candidate-name-info {
  font-weight: $font-weight-bold;
}
