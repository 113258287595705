/* Copyright 2020 Scout Exchange, LLC. All Rights Reserved. */

// ===========================
// Used for Jobs and Candidate widgets
// ===========================

.widget-tab-wrapper {
  @extend .list-unstyled;

  border-bottom: 1px solid $primary-variant-lighter;
  display: flex;
  margin-bottom: spacer(1);
}

.widget-tab-content {
  font-size: $small-font-size;
  padding-top: spacer(2);
}

.widget-tab-action {
  color: inherit;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0 10%;
  position: relative;
}

.widget-tab {
  color: $scout-action;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;

  .widget-tab-value {
    font-size: 32px;
    font-weight: $font-weight-light;
    line-height: 1;
  }

  .widget-tab-title {
    font-size: $small-font-size;
    margin-bottom: 6px;
  }

  .widget-tab-content {
    padding: 0;
    position: relative;
    margin: 0 4px;
  }

  &.active {
    color: $scout-primary;
    cursor: auto;
  }

  &.active,
  &:hover {
    .widget-tab-action {
      &::after {
        background-color: $scout-status;
        bottom: -2px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}
