/* Copyright 2020 Scout Exchange, LLC. All Rights Reserved. */

$atlantis: #89bf30; // green
$alto: #d8d7d7; // gray

.gauge-chart {
  display: inline-block;
  position: relative;
  margin-bottom: spacer(4);
}

.gauge-chart-data {
  display: block;
  position: relative;

  /* height and width values determined by comparing
  screen output with UX design */
  width: px-to-rem(150);
  height: px-to-rem(129);
  margin: 0 auto;
  z-index: 10;

  .chartjs-render-monitor {
    position: relative;
    z-index: 10;
  }
}

.gauge-chart-thumb-wrap {
  position: absolute;
  content: "";
  border-radius: 50%;
  height: px-to-rem(150);
  top: 5px;
  transform: rotate(0deg);
  transition: all 1s;
  width: px-to-rem(150);
  z-index: 1;
}

.gauge-chart-thumb {
  position: absolute;
  bottom: 15px;
  left: 21px;
  font-size: 18px;
}

.gauge-chart-track {
  height: px-to-rem(139);
  left: 5px;
  position: absolute;
  top: 0;
  width: px-to-rem(140);

  path {
    fill: transparent;
    stroke: #d8d7d7;
    stroke-width: 3px;
  }
}

.gauge-chart-details {
  position: absolute;
  text-align: center;
  top: px-to-rem(48);
  width: 100%;
}

.gauge-chart-req-label {
  position: absolute;
  text-align: center;
  width: 100%;
  top: px-to-rem(32);
  font-size: px-to-rem(12);
  font-weight: $font-weight-light;
}

.gauge-chart-value {
  font-size: px-to-rem(36);
  font-weight: $font-weight-light;
  line-height: 1;
  margin-top: 3px;
}

.gauge-chart-unit {
  font-size: px-to-rem(16);
  font-weight: $font-weight-light;
  line-height: 1;
  margin-top: 3px;
}

.gauge-chart-target {
  background-color: $atlantis;
  border-radius: px-to-rem(20);
  color: $white;
  font-size: $small-font-size;
  font-weight: $font-weight-semibold;
  margin: 0 auto;
  padding: 1px 5px;
  position: relative; // needed for z-index to work
  text-align: center;
  width: px-to-rem(90);
}
