// Copyright 2013-2019 Scout Exchange, LLC. All Rights Reserved.

// ===========================
// global Dashboard styles
// ===========================

.btn {
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

a,
.btn-link {
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

// remove underlines on '<a>' elements within a dropdown-menu
.dropdown-menu {
  a {
    text-decoration: none;
  }
}

// Headers --------
// redefine sizes for how they're used in Dashboard
// ===========================

h1,
%h1,
h2,
%h2,
h3,
%h3,
h4,
%h4,
h5,
%h5,
h6,
%h6 {
  color: $scout-primary;
}

h2,
%h2 {
  font-size: $font-size-base;
  font-weight: $font-weight-semibold;
  line-height: inherit;
  margin: 6px 0 7px;
}

h3,
%h3 {
  margin: 0;
  font-size: px-to-rem(18);
  font-weight: $font-weight-normal;
}

.link-icon-left {
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: spacer(1);
  }
}
