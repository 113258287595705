/* Copyright 2021-2023 Scout Exchange, LLC. All Rights Reserved. */

.total-spend-head {
  text-align: center;
}

.total-spend-title {
  font-size: px-to-rem(28);
  line-height: 1.4;
}

.total-spend-sub-head {
  font-size: px-to-rem(16);
  margin-bottom: 5px;
  text-align: center;
}

.total-spend-chart-wrap {
  height: px-to-rem(160);
  width: px-to-rem(320);

  @media (min-width: $sm-break) {
    height: px-to-rem(210);
    width: px-to-rem(420);
  }
}
