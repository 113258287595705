/* Copyright 2020 Scout Exchange, LLC. All Rights Reserved. */

// ArrowIcon

.svg-arrow {
  height: 19px;
  width: 9px;
}

.svg-arrow-path {
  stroke: $brand-danger;
  stroke-width: 1;
  fill: transparent;
}

.svg-arrow-down {
  transform: rotate(180deg);
}

.svg-arrow-good {
  .svg-arrow-path {
    stroke: $brand-success;
  }
}

.svg-external-link {
  path {
    fill: transparent;
    stroke: $white;
  }
}

.svg-zero-state-bar-chart {
  height: 132px;
  width: 151px;
}

.svg-zero-state-donut-chart {
  width: 158px;
  height: 158px;

  > path {
    stroke-width: 11;
    fill: none;
  }
}

.svg-zero-state-line-chart {
  height: 143px;
  width: 273px;

  > path {
    fill: none;
  }
}

.svg-pipeline-zero-state {
  height: px-to-rem(158);
  width: px-to-rem(147);

  &-body,
  &-funnel {
    fill: transparent;
    stroke-linecap: round;
    stroke-width: 3px;
  }

  &-body {
    stroke: $scout-action;
  }

  &-funnel {
    stroke: $gray-dark;
  }
}

.svg-download {
  height: 17px;
  width: 17px;

  .svg-download-path {
    fill: transparent;
    stroke: $scout-action;
  }

  .svg-download-circle {
    fill: transparent;
    stroke: $scout-action;
  }
}

// IconStar

.material-icon-star {
  font-size: px-to-rem(24);
  font-weight: $font-weight-normal;
}
