// Copyright 2013-2023 Scout Exchange, LLC. All Rights Reserved.

// ===========================
// Layout styles
// ===========================

// NOTE: Once a grid package is put in place to manage layout and
// positioning functionality, most of these styles can be removed.

@function num-cols($col-count) {
  //   sum of widget widths in a layout
  // + the space between them (number of columns - 1)
  // + the space around them (always two because there's only 2 sides)
  // = $result
  $result: calc(($widget-width * $col-count) + ($grid-gap * ($col-count - 1)) + ($container-pad * 2));

  // (36rem * 2) + (2.8rem * 1) + (2.8rem * 2)
  // 72rem + 2.8rem + 5.6rem
  // 80.4rem
  @return $result;
}

$xs-width: num-cols(1);
$sm-width: num-cols(2);
$md-width: num-cols(3);


// used to calculate breakpoints specific to Dashboard
$sm-break: calc($sm-width + $extra-break-space);
$md-break: calc($md-width + $extra-break-space);

.l-wrap {
  background-color: $black-squeeze;
}

// force padding of 2em at all breakpoints
.l-main {
  background-color: transparent;
  padding: 0 spacer(8);
}

.l-flex {
  margin: 0 auto;
  width: $xs-width;
}

.dash-layout {
  display: flex;
  flex-wrap: wrap;
  gap: px-to-rem(32);

  .col-one-third,
  .col-half,
  .col-two-thirds,
  .col-full {
    width: $widget-width;
  }

  @media (min-width: $sm-break) {
    .col-one-third {
      width: $widget-width;
    }

    .col-half,
    .col-two-thirds,
    .col-full {
      width: 100%;
    }
  }

  @media (min-width: $md-break) {
    .col-half {
      width: calc(($widget-width * 1.5) + ($grid-gap * 0.5));
    }

    .col-two-thirds {
      width: calc(($widget-width * 2) + $grid-gap);
    }
  }
}

@media (min-width: $sm-break) {
  .l-flex {
    width: $sm-width;
  }
}

@media (min-width: $md-break) {
  .l-flex {
    width: $md-width;
  }
}
